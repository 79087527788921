<template>
  <div class="container" v-loading="loading">

      <div class="row course-part">
        <div class="video-part-title">推荐 题库</div>
        <div class="card-deck" >
          <div class="col-xs-6 col-sm-4 col-md-3" v-for="item in list" :key="item.id">

            <div class="book-image">
              <router-link :to="{ path: item.link, query: { id: item.id } }">
                <img class="card-img-top"
                  :src="item.image"
                  :item="item.title"
                  :alt="item.title" />
              </router-link>
              <div class="book-title">
                <router-link :to="{ path: item.link, query: { id: item.id } }" class="book-title-a">
                  {{ item.title }}
                </router-link>
              </div>
            </div>

          </div>
        </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  // name: 'Index',
  props:['user'],
  data() {
    return {
      sliders: null,
      list: null,
      loading: true,
    }
  },
  created() {
    this.getNewCourseList()
  },
  methods: {
    async getNewCourseList() {     
      const response = await axios.post('/api/web/questionLibrary/recommendList')
      const items = response.data.data.data
      this.list = items
      // console.log(this.list)
      this.loading = false
    },
    async course(id) {
      this.$router.push({path:`/course/${id}`})
    }
  }
}
</script>
